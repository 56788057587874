<template>
  <div class="exchange">
    <div class="trade_content">
      <el-row class="trade_content_flex">
        <el-col :span="10" class="grid_item">
          <div class="item_tit">
            <!-- <img src="@/assets/images/ai1001.png" alt="" width="90" height="35"> -->
            <span>{{$t('lang.jiaoyi.text1')}}</span>
          </div>
          <div class="numbers-container">
            <div class="numbers-box"
              v-for="(number,index) in numberList.slice(0,2)" :key="'numbers1'+index">
              <span 
                :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                v-if="currentGmv > 0">
                <label>0</label>
                <label>1</label>
                <label>2</label>
                <label>3</label>
                <label>4</label>
                <label>5</label>
                <label>6</label>
                <label>7</label>
                <label>8</label>
                <label>9</label>
                <label>0</label>
              </span>
              <span v-else>
                <label>0</label>
              </span>
            </div>
            <div class="dou">,</div>
            <div class="numbers-box"
              v-for="(number, index) in numberList.slice(2,5)" :key="'numbers2'+index">
              <span 
                :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                v-if="currentGmv > 0">
                <label>0</label>
                <label>1</label>
                <label>2</label>
                <label>3</label>
                <label>4</label>
                <label>5</label>
                <label>6</label>
                <label>7</label>
                <label>8</label>
                <label>9</label>
                <label>0</label>
              </span>
              <span v-else>
                <label>0</label>
              </span>
            </div>
            <div class="dou">,</div>
            <div class="numbers-box"
              v-for="(number,index) in numberList.slice(5,8)" :key="'numbers3'+index">
              <span 
                :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                v-if="currentGmv > 0">
                <label>0</label>
                <label>1</label>
                <label>2</label>
                <label>3</label>
                <label>4</label>
                <label>5</label>
                <label>6</label>
                <label>7</label>
                <label>8</label>
                <label>9</label>
                <label>0</label>
              </span>
              <span v-else>
                <label>0</label>
              </span>
            </div>
            <div class="dou">.</div>
            <div class="numbers-box"
              v-for="(number,index) in numberList1" :key="'numbers4'+index">
              <span 
                :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                v-if="currentGmv > 0">
                <label>0</label>
                <label>1</label>
                <label>2</label>
                <label>3</label>
                <label>4</label>
                <label>5</label>
                <label>6</label>
                
                <label>7</label>
                <label>8</label>
                <label>9</label>
                <label>0</label>
              </span>
              <span v-else>
                <label>0</label>
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="6" class="grid_item">
          <div class="item_tit">{{$t('lang.jiaoyi.text2')}}</div>
          <div class="pay_bill" :style="{marginTop: 0}">
            <div class="pay_bill_text">Buy</div>
            <div class="numbers-container">
              <div class="numbers-box"
                v-for="(number,index) in payBillList" :key="'payNumbers1'+index">
                <span 
                  :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                  v-if="payBill > 0">
                  <label>0</label>
                  <label>1</label>
                  <label>2</label>
                  <label>3</label>
                  <label>4</label>
                  <label>5</label>
                  <label>6</label>
                  <label>7</label>
                  <label>8</label>
                  <label>9</label>
                  <label>0</label>
                </span>
                <span v-else>
                  <label>0</label>
                </span>
              </div>
            </div>
          </div>
          <div class="pay_bill">
            <div class="pay_bill_text1">Sell</div>
            <div class="numbers-container">
              <div class="numbers-box"
                v-for="(number,index) in sellBillList" :key="'sellNumbers1'+index">
                <span 
                  :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                  v-if="sellBill > 0">
                  <label>0</label>
                  <label>1</label>
                  <label>2</label>
                  <label>3</label>
                  <label>4</label>
                  <label>5</label>
                  <label>6</label>
                  <label>7</label>
                  <label>8</label>
                  <label>9</label>
                  <label>0</label>
                </span>
                <span v-else>
                  <label>0</label>
                </span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="4" class="grid_item">
          <div class="item_tit">{{$t('lang.jiaoyi.text3')}}</div>
          <div class="numbers-container">
            <div class="numbers-box"
              v-for="(number,index) in userNumList" :key="'userNumbers1'+index">
              <span 
                :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                v-if="userNum > 0">
                <label>0</label>
                <label>1</label>
                <label>2</label>
                <label>3</label>
                <label>4</label>
                <label>5</label>
                <label>6</label>
                <label>7</label>
                <label>8</label>
                <label>9</label>
                <label>0</label>
              </span>
              <span v-else>
                <label>0</label>
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="5" class="grid_item">
          <div>
            <div class="item_tit">{{$t('lang.jiaoyi.text4')}}</div>
            <div class="numbers-container">
              <div class="numbers-box rate-color"
                v-for="(number,index) in rateList" :key="'rateNumbers1'+index">
                <span 
                  :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                  v-if="currentRate > 0">
                  <label>0</label>
                  <label>1</label>
                  <label>2</label>
                  <label>3</label>
                  <label>4</label>
                  <label>5</label>
                  <label>6</label>
                  <label>7</label>
                  <label>8</label>
                  <label>9</label>
                  <label>0</label>
                </span>
                <span v-else>
                  <label>0</label>
                </span>
              </div>
              <div class="dou rate-dou">.</div>
              <div class="numbers-box rate-color"
                v-for="(number, index) in rateList1" :key="'rateNumbers2'+index">
                <span 
                  :class="number===0?'numbers-scroll0'+Math.random*5:'numbers-scroll'+ number"
                  v-if="currentRate > 0">
                  <label>0</label>
                  <label>1</label>
                  <label>2</label>
                  <label>3</label>
                  <label>4</label>
                  <label>5</label>
                  <label>6</label>
                  <label>7</label>
                  <label>8</label>
                  <label>9</label>
                  <label>0</label>
                </span>
                <span v-else>
                  <label>0</label>
                </span>
              </div>
              <div class="rate_label">%</div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="state">{{$t('lang.jiaoyi.text5')}}</div>
    </div>
  </div>
</template>
<script>
import "./Index.less";
import { exchangeorder} from '../../interface.js'
export default {
  name: 'Announcement',
  data() {
    return {
      currentGmv: '20430.98',
      numberList: [],
      numberList1: [],
      currentRate: '26.134',
      rateList: [],
      rateList1: [],
      userNum: '261',
      userNumList: [],
      payBill: '1234',
      payBillList: [],
      sellBill: '56789',
      sellBillList: [],
    }
  },
  methods: {
    exchangeorder () {
      exchangeorder(res=>{
        // console.log(res)
        if(res.code==200){
          this.currentGmv= res.exchange_all;
          this.sellBill= res.sell_order;
          this.payBill= res.buy_order;
          this.userNum= res.user_count;
          this.currentRate= res.year_earnings;
          //Ai只能交易系统24H交易总额
          this.currentGmv = Number(this.currentGmv).toFixed(2);
          this.numberList = this.currentGmv.substring(0, this.currentGmv.indexOf(".")).split("");
          var numberListLength = 8-this.numberList.length;
          if(numberListLength>0){
            for(let i=0;i <numberListLength ;i++){
              this.numberList.unshift('0');
            }
          }
          this.numberList1 = this.currentGmv.split(".")[1].split("");

          //系统用户数量
          this.userNum = Number(this.userNum).toFixed(0);
          this.userNumList = this.userNum.split("");
          var userNumListLength = 4-this.userNumList.length;
          if(userNumListLength>0){
            for(let i=0;i <userNumListLength ;i++){
              this.userNumList.unshift('0');
            }
          }

          //综合年化收益率
          this.currentRate = Number(this.currentRate).toFixed(2);
          this.rateList = this.currentRate.substring(0, this.currentRate.indexOf(".")).split("");
          const rateListLength = 2-this.rateList.length;
          if(rateListLength>0){
            for(let i=0;i <rateListLength ;i++){
              this.rateList.unshift('0');
            }
          }
          this.rateList1 = this.currentRate.split(".")[1].split("");
          
            //系统24H交易单量-买单
            this.payBill = Number(this.payBill).toFixed(0);
            this.payBillList = (this.payBill + "").split("");
            const payBillListLength = 7-this.payBillList.length;
            if(payBillListLength>0){
              for(let i=0;i <payBillListLength ;i++){
                this.payBillList.unshift('0');
              }
            }
            //系统24H交易单量-卖单
            this.sellBill = Number(this.sellBill).toFixed(0);
            this.sellBillList = (this.sellBill + "").split("");
            const sellBillListLength = 7-this.sellBillList.length;
            if(sellBillListLength>0){
              for(let i=0;i <sellBillListLength ;i++){
                this.sellBillList.unshift('0');
              }
            }
        }
      })
    },
  },
  mounted () {
    this.exchangeorder ();
    // this.tacticsmessage();
    //Ai只能交易系统24H交易总额
    this.currentGmv = Number(this.currentGmv).toFixed(2);
    this.numberList = this.currentGmv.substring(0, this.currentGmv.indexOf(".")).split("");
    var numberListLength = 8-this.numberList.length;
    if(numberListLength>0){
      for(let i=0;i <numberListLength ;i++){
        this.numberList.unshift('0');
      }
    }
    this.numberList1 = this.currentGmv.split(".")[1].split("");

    //系统用户数量
    this.userNum = Number(this.userNum).toFixed(0);
    this.userNumList = this.userNum.split("");
    var userNumListLength = 4-this.userNumList.length;
    if(userNumListLength>0){
      for(let i=0;i <userNumListLength ;i++){
        this.userNumList.unshift('0');
      }
    }

    //综合年化收益率
    this.currentRate = Number(this.currentRate).toFixed(2);
    this.rateList = this.currentRate.substring(0, this.currentRate.indexOf(".")).split("");
    const rateListLength = 2-this.rateList.length;
    if(rateListLength>0){
      for(let i=0;i <rateListLength ;i++){
        this.rateList.unshift('0');
      }
    }
    this.rateList1 = this.currentRate.split(".")[1].split("");
    
      //系统24H交易单量-买单
      this.payBill = Number(this.payBill).toFixed(0);
      this.payBillList = (this.payBill + "").split("");
      const payBillListLength = 7-this.payBillList.length;
      if(payBillListLength>0){
        for(let i=0;i <payBillListLength ;i++){
          this.payBillList.unshift('0');
        }
      }
      //系统24H交易单量-卖单
      this.sellBill = Number(this.sellBill).toFixed(0);
      this.sellBillList = (this.sellBill + "").split("");
      const sellBillListLength = 7-this.sellBillList.length;
      if(sellBillListLength>0){
        for(let i=0;i <sellBillListLength ;i++){
          this.sellBillList.unshift('0');
        }
      }
  }
}
</script>
