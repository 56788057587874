<template>
  <div>
    <Header :id="2"></Header>
    <div class="aimain">
      <Ai100Header></Ai100Header>
      <div class="exchange_box">
        <Exchange></Exchange>
      </div>
      <div class="aimain_container">
        <div class="sys_robot">
          <div class="service">
            <div class="service_item" v-for="item in system_list" :key="item.id">
              <div class="item_l">
                <div class="tit">{{$t(item.title1)}}</div>
                <div class="tit2">{{$t(item.title2)}}</div>
                <div class="item_l_b">
                  <div class="pt">
                    <div class="pt_text">{{$t(item.pt)}}：</div>
                    <div class="pt_con">
                      <img v-for="(item,index) in item.pt_img" :src="item" :key="index" width="24" height="24" class="pt_img">
                    </div>
                  </div>
                  <div class="service_status">
                    <div class="status_text">{{$t(item.status_text)}}：</div>
                    <div :class="item.status==1?'status_con green':'status_con red'">{{$t(item.status_desc)}}</div>
                  </div>
                </div>
                <div class="top" v-if="item.id==1">
                  <img src="@/assets/images/top.png" width="45" height="45">
                </div>
              </div>
            </div>
          </div>
          <div class="business">
              <div class="item_r">
                <div class="legend">
                  <div class="le_box">
                    <div class="le">
                      <div class="kuai1"></div>
                      <div class="kuai_t">Overal market trend</div>
                    </div>
                    <div class="le">
                      <div class="kuai2"></div>
                      <div class="kuai_t">Ai100 Meen profit</div>
                    </div>
                  </div>
                  <div class="more">More+</div>
                </div>
                <div id="line2"></div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'
import Ai100Header from '../../components/Ai100Header/Index'
import Exchange from '../../components/Exchange/Index'
import moment from 'moment'
import { exchangeorder,echartsvalue  } from '../../interface.js'
export default {
  components: {
    Header,
    Footer,
    Ai100Header,
    Exchange,
  },
  name: 'Aimain',
  data() {
    return {
      currentGmv: '20430.98',
      numberList: [],
      numberList1: [],
      currentRate: '26.134',
      rateList: [],
      rateList1: [],
      userNum: '261',
      userNumList: [],
      payBill: '1234',
      payBillList: [],
      sellBill: '56789',
      sellBillList: [],
      data1: ["12.93", "12.93", "12.93", "1.16", "0.51", "0.25", "1.12", "0.90", "0.77", "0.73", "1.12", "1.30", "12.93", "0.88", "0.70"],
      data2: ["6.70", "10.61", "8.65", "6.88", "3.30", "8.63", "6.84", "0.55", "0.55", "0.55", "0.55", "0.55", "0.55", "10.96", "8.93"],
      data_time: ["10.12", "10.11", "10.10", "10.09", "10.08", "10.07", "10.06", "10.05", "10.04", "10.03", "10.02", "10.01", "09.30", "09.29", "09.28"],
      system_list: [
        {
          id: 1,
          title1: 'lang.systemList.item1.title1',
          title2: 'lang.systemList.item1.title2',
          pt: 'lang.systemList.pt',
          pt_img: [require('@/assets/images/icon/bnb_icon.png'),require('@/assets/images/icon/huobi_icon.png'),require('@/assets/images/icon/okb_icon.png'),require('@/assets/images/icon/reyuip_icon.png')
          ],
          status_text: 'lang.systemList.status_text',
          status_desc: 'lang.systemList.status_desc1',
          status: 1
        },
        {
          id: 2,
          title1: 'lang.systemList.item2.title1',
          title2: 'lang.systemList.item2.title2',
          pt: 'lang.systemList.pt',
          pt_img:  [require('@/assets/images/icon/uni_icon.png'),require('@/assets/images/icon/rey_icon.png'),require('@/assets/images/icon/m_icon.png')
          ],
          status_text: 'lang.systemList.status_text',
          status_desc: 'lang.systemList.status_desc',
          status: 0
        },
        {
          id: 3,
          title1: 'lang.systemList.item3.title1',
          title2: 'lang.systemList.item3.title2',
          pt: 'lang.systemList.pt',
          pt_img: [require('@/assets/images/icon/btmax_icon.png'),require('@/assets/images/icon/bnb_icon.png'),require('@/assets/images/icon/okb_icon.png'),require('@/assets/images/icon/huobi_icon.png')
          ],
          status_text: 'lang.systemList.status_text',
          status_desc: 'lang.systemList.status_desc',
          status: 0
        }
      ]
    }
  },
  methods: {
    sys_robot_click (num) {
      this.$router.push({path:'/SysRobotDetails',query:{id:num}})
    },
    exchangeorder () {
      exchangeorder(res=>{
        // console.log(res)
        if(res.code==200){
          this.currentGmv= res.exchange_all;
          this.sellBill= res.sell_order;
          this.payBill= res.buy_order;
          this.userNum= res.user_count;
          this.currentRate= res.year_earnings;
          //Ai只能交易系统24H交易总额
          this.currentGmv = Number(this.currentGmv).toFixed(2);
          this.numberList = this.currentGmv.substring(0, this.currentGmv.indexOf(".")).split("");
          var numberListLength = 8-this.numberList.length;
          if(numberListLength>0){
            for(let i=0;i <numberListLength ;i++){
              this.numberList.unshift('0');
            }
          }
          this.numberList1 = this.currentGmv.split(".")[1].split("");

          //系统用户数量
          this.userNum = Number(this.userNum).toFixed(0);
          this.userNumList = this.userNum.split("");
          var userNumListLength = 4-this.userNumList.length;
          if(userNumListLength>0){
            for(let i=0;i <userNumListLength ;i++){
              this.userNumList.unshift('0');
            }
          }

          //综合年化收益率
          this.currentRate = Number(this.currentRate).toFixed(2);
          this.rateList = this.currentRate.substring(0, this.currentRate.indexOf(".")).split("");
          const rateListLength = 2-this.rateList.length;
          if(rateListLength>0){
            for(let i=0;i <rateListLength ;i++){
              this.rateList.unshift('0');
            }
          }
          this.rateList1 = this.currentRate.split(".")[1].split("");
          
            //系统24H交易单量-买单
            this.payBill = Number(this.payBill).toFixed(0);
            this.payBillList = (this.payBill + "").split("");
            const payBillListLength = 7-this.payBillList.length;
            if(payBillListLength>0){
              for(let i=0;i <payBillListLength ;i++){
                this.payBillList.unshift('0');
              }
            }
            //系统24H交易单量-卖单
            this.sellBill = Number(this.sellBill).toFixed(0);
            this.sellBillList = (this.sellBill + "").split("");
            const sellBillListLength = 7-this.sellBillList.length;
            if(sellBillListLength>0){
              for(let i=0;i <sellBillListLength ;i++){
                this.sellBillList.unshift('0');
              }
            }
        }
      })
    },
    getEchartInfo () {
      echartsvalue(res=>{
        // console.log(res)
        if(res.code==200){
          var earnings = res.earnings;
          var principal = res.principal;
          var data1_priceval = [];
          var data1_timedate = [];
          earnings.map(item => {
            if(item.typeval==1){
              data1_priceval.push(item.priceval.slice(0,-1));
              data1_timedate.push(moment(item.timedate*1000).format("MM.DD"));
            }
          })
         
          var data2_priceval = [];
          var data2_timedate = [];
          principal.map(item => {
            if(item.typeval==1){
              data2_priceval.push(item.priceval.slice(0,-1));
              data2_timedate.push(moment(item.timedate*1000).format("MM.DD"));
            }
          })
          console.log(data1_priceval)
          // console.log(data2_timedate)
          this.$chart.line2('line2',data1_priceval,data2_priceval,data2_timedate);
        }
      })
    },
  },
  mounted () {
    this.exchangeorder ();
    this.getEchartInfo ();
    // this.tacticsmessage();
    //Ai只能交易系统24H交易总额
    this.currentGmv = Number(this.currentGmv).toFixed(2);
    this.numberList = this.currentGmv.substring(0, this.currentGmv.indexOf(".")).split("");
    var numberListLength = 8-this.numberList.length;
    if(numberListLength>0){
      for(let i=0;i <numberListLength ;i++){
        this.numberList.unshift('0');
      }
    }
    this.numberList1 = this.currentGmv.split(".")[1].split("");

    //系统用户数量
    this.userNum = Number(this.userNum).toFixed(0);
    this.userNumList = this.userNum.split("");
    var userNumListLength = 4-this.userNumList.length;
    if(userNumListLength>0){
      for(let i=0;i <userNumListLength ;i++){
        this.userNumList.unshift('0');
      }
    }

    //综合年化收益率
    this.currentRate = Number(this.currentRate).toFixed(2);
    this.rateList = this.currentRate.substring(0, this.currentRate.indexOf(".")).split("");
    const rateListLength = 2-this.rateList.length;
    if(rateListLength>0){
      for(let i=0;i <rateListLength ;i++){
        this.rateList.unshift('0');
      }
    }
    this.rateList1 = this.currentRate.split(".")[1].split("");
    
      //系统24H交易单量-买单
      this.payBill = Number(this.payBill).toFixed(0);
      this.payBillList = (this.payBill + "").split("");
      const payBillListLength = 7-this.payBillList.length;
      if(payBillListLength>0){
        for(let i=0;i <payBillListLength ;i++){
          this.payBillList.unshift('0');
        }
      }
      //系统24H交易单量-卖单
      this.sellBill = Number(this.sellBill).toFixed(0);
      this.sellBillList = (this.sellBill + "").split("");
      const sellBillListLength = 7-this.sellBillList.length;
      if(sellBillListLength>0){
        for(let i=0;i <sellBillListLength ;i++){
          this.sellBillList.unshift('0');
        }
      }
  },
  created ()  {
    if(localStorage.token==""||localStorage.token==null||localStorage.token==undefined){     
        this.$router.push('/');
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/animations.css';
@import '../../assets/styles/varibles.less';
.aimain{
  width: 100%;
  min-height: calc(100% - 286px);
  overflow: hidden;
  padding-bottom: 80px;
  .exchange_box{
    padding: 38px 0 52px 0;
    background: #fff;
  }
  .aimain_container{
    min-width: @minWidth;
    max-width: @maxWidth;
    margin: 0 auto;
  }
  .sys_robot{
    padding-bottom: 49px;
    display: flex;
    justify-content: space-between;
    .service{
      display: flex;
      flex-direction: column;
      .service_item{
        min-width: 611px;
        padding: 21px;
        border: 1px solid @borderColor;
        border-radius: 6px;
        display: flex;
        margin-bottom: 22px;
        position: relative;
        cursor: pointer;
        .top{
          position: absolute;
          bottom: -5px;
          right: -5px;
        }
        .item_l{
          display: flex;
          flex-direction: column;
          font-size: @font14;
          line-height: @font14;
          .tit{
            font-size: @font24;
            line-height: @font24;
            font-weight: @fontWeight[500];
            color: @grey[2];
          }
          .tit2{
            color: @grey[1];
            margin: 6px 0 14px 0;
          }
          .item_l_b{
            display: flex;
            align-items: center;
            .pt{
              display: flex;
              align-items: center;
              width: 246px;
            }
            .pt_img{
              border: 1px solid rgba(0,0,0,0.1);
              border-radius: 4px;
              margin-right: 10px;
            }
            .service_status{
              display: flex;
              align-items: center;
              .red{
                color: @red1;
              }
              .green{
                color: @green1;
              }
            }
          }
        }
      }
      .service_item:hover{
        box-shadow: 0 0 16px rgba(36, 43, 50, 0.2);
      }
      .service_item:last-child{
        margin-bottom: 0;
      }
    }
    .business{
      .item_r{
        width: 555px;
        height: 346px;
        .legend{
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 0 22px 4px;
          color: rgba(0,0,0,0.6);
          .le_box{
            display: flex;
            align-items: center;
            .le{
              display: flex;
              align-items: center;
              margin-right: 20px;
              font-size: 12px;
              .kuai1{
                width: 12px;
                height: 12px;
                margin-right: 5px;
                background: #ff0391;
              }
              .kuai2{
                width: 12px;
                height: 12px;
                margin-right: 5px;
                background: #00ffee;
              }
            }
          }
        }
        #line2,#line4,#line5{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
</style>